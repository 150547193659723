import React, { Fragment } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCheck } from "@fortawesome/pro-light-svg-icons/faUserCheck";
import { faGraduationCap } from "@fortawesome/pro-light-svg-icons/faGraduationCap";
import { faDonate } from "@fortawesome/pro-light-svg-icons/faDonate";
import { faWeight } from "@fortawesome/pro-light-svg-icons/faWeight";
import { faCalendarDay } from "@fortawesome/pro-light-svg-icons/faCalendarDay";

export default function Junk101() {
  return (
    <Fragment>
      <SEO title="Junk Removal 101" />
      <Layout fluid={false}>
        <h1>Junk Removal 101</h1>
        <div><FontAwesomeIcon icon={faGraduationCap} size="lg" color="#000099" style={{marginLeft: "auto", display: "block"}}/></div>
        <hr />
        <p>
        So you need to get some items removed from your residence.  What is the process?  What if some of the items are not garbage?  We will discuss the basics of junk removal so you know what to expect.
        </p>
        <h2>Step One – Decide on items to be removed and/or donated.</h2>
        <div><FontAwesomeIcon icon={faDonate} size="lg" color="#000099" style={{marginLeft: "auto", display: "block"}}/></div>
        <hr />
        <p>
        Make a decision on what items you want to get rid of.  This can be a few items or a full truck or trucks.  Sometimes this step is the hardest part.  If you would like to make a pile in the garage or basement or backyard, this is one of the best ways to stay organized during this process.  Another option is to place post it notes on the items for removal.  Sometimes big or heavy items cannot be moved around the house easily.  This is ok.  We will retrieve these items wherever they are.
        <br />
        </p>
        <p><b>We can and do donate any reusable items to your local Goodwill or charity.  A donation receipt will be provided.</b></p>
        <h2>Step Two – Price is determined by volume, weight, and ease of loading.</h2>
        <div><FontAwesomeIcon icon={faWeight} size="lg" color="#000099" style={{marginLeft: "auto", display: "block"}}/></div>
        <hr />
        <p>
          Junk Removal is priced mainly by one factor - <b>volume</b>.  The amount of space your items take up in the truck is going to dictate the price.  Volume is estimated by cubic yards.  If you picture a washing machine, this is <b>one cubic yard</b>.  Most trucks are 15-20 cubic yards.  As a rule of thumb, you will pay $25-$30/cubic yard.  So if you wanted to get rid of (4) washing machines, you could expect to pay $100-$120. 
        </p>
        <p>
        Another factor to consider in the price of junk removal will be the <b>weight</b> of the material or items being removed.  Heavy material such as cement, rock, dirt, shingles, wood, drywall are just some examples.  A load that exceeds weight limits for normal pricing will incur <b>an additional fee of $50-$80/ton</b>.  A ton is 2,000lbs.  Usually the price of junk removal services includes up to 4,000lbs.  It is after 4000lbs when addition weight charges will kick in.
        </p>
        <p>
        Consider the <b>ease of loading</b> as a price factor for your junk removal as well.  It is much easier to load items at the curb or driveway versus down and around in a basement area.  If your residence is an apartment or you live in a high-rise building where an elevator is necessary, then the ease of loading decreases tremendously.  These labor-intensive loads that last longer than one hour will typically increase the price.
        </p>
        <h2>Step Three – Choosing a service provider</h2>
        <div><FontAwesomeIcon icon={faUserCheck} size="lg" color="#000099" style={{marginLeft: "auto", display: "block"}}/></div>
        <hr />
        <p>Whenever we consider any service provider for our needs, we typically    decide by price, availability, online reviews, referrals from friends, and location.  This can be a lot of work to gather enough data to make a purchasing decision.  If we are in a hurry, we may just go with the first one to answer the phone.  </p>
        <p>Junk Removal Dispatch solves these common problems with finding a service provider.  You will see clear pricing, availability, and online reviews all in one place.  You will be able to find a local pro and read their profile.  We all love to support the local guy!</p>
        <p>Booking your service provider can usually be done online or with a phone call.  If you have questions about anything, it is best to make the phone call and address any questions over the phone.  Once you decide on a date and time, get ready for the excitement of the pick up.  <b>It feels good to get rid of stuff</b>…trust us.</p>
        <h2>Step Four – 	Junk Removal Day</h2>
        <div><FontAwesomeIcon icon={faCalendarDay} size="lg" color="#000099" style={{marginLeft: "auto", display: "block"}}/></div>
        <hr />
        <p>Do not lift a finger at this point.  Let your junk removal specialist do all the      loading, lifting, and grunting.  Ensure proper instructions are provided on what is going and what is staying.  If you have any donations, make sure to clarify first.  Before the junk truck closes the doors and puts the tarp on, make sure all items have been loading.  Most junk removal companies will ask you if they got everything loaded before they give you the final price.  This is a good time to mention any last minute items or ask for a quote on a future project.  Now is the time to get as much done as you can in regards to junk removal.  </p>
        <p>Make sure you are satisfied with the service.  In fact, posting an online review is an excellent way to spread the news to your local community.</p>
        <p>Congratulations!  It is at this point in which you accomplish a tough step in parting with your belongings.  You are well on your way of becoming a minimalist…or at least paying less in storage fees.</p>

      </Layout>
    </Fragment>
  )
}